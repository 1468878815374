import React, { createContext, useState, useContext, useEffect } from 'react';
import i18n from 'i18next';
import { Client, Account } from 'appwrite';

// Initialize Appwrite
const client = new Client();
client.setEndpoint('https://cloud.appwrite.io/v1')
    .setProject('6748fa610024d013c88b');

const account = new Account(client);

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem('i18nextLng') || 'en');
  const [isRTL, setIsRTL] = useState(currentLanguage === 'ar');
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Load user data and preferences on initial mount
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setIsLoading(true);
        const user = await account.get();
        setUserData(user);
        
        // If user has language preference set in their profile
        if (user && user.prefs && user.prefs.language) {
          const userPreferredLang = user.prefs.language;
          
          if (userPreferredLang !== currentLanguage) {
            console.log(`Loading user's preferred language: ${userPreferredLang}`);
            await i18n.changeLanguage(userPreferredLang);
            setCurrentLanguage(userPreferredLang);
          }
        }
      } catch (error) {
        console.log("User not logged in or error fetching user data");
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, []);

  // Apply RTL settings whenever language changes
  useEffect(() => {
    const isRtl = currentLanguage === 'ar';
    setIsRTL(isRtl);
    
    // Set HTML dir attribute
    document.documentElement.dir = isRtl ? 'rtl' : 'ltr';
    
    // Add RTL class to body
    if (isRtl) {
      document.body.classList.add('rtl');
    } else {
      document.body.classList.remove('rtl');
    }
    
    // Also store in localStorage for persistence
    localStorage.setItem('i18nextLng', currentLanguage);
    
  }, [currentLanguage]);

  // The function to change language with proper user preference saving
  const changeLanguage = async (language) => {
    console.log(`Changing language to: ${language}`);
    
    try {
      // First update i18n
      await i18n.changeLanguage(language);
      setCurrentLanguage(language);
      
      // Then try to update user preferences if logged in
      if (userData) {
        try {
          console.log("Updating user language preference in Appwrite");
          
          // Get current preferences and update language
          const currentPrefs = userData.prefs || {};
          const updatedPrefs = { 
            ...currentPrefs,
            language 
          };
          
          // Save to Appwrite
          const updatedUser = await account.updatePrefs(updatedPrefs);
          setUserData({
            ...userData,
            prefs: updatedUser.prefs
          });
          
          console.log("User language preference updated successfully");
        } catch (error) {
          console.error("Failed to update user language preference:", error);
        }
      } else {
        console.log("User not logged in, only updating local language");
      }
    } catch (error) {
      console.error("Error changing language:", error);
    }
  };

  const contextValue = {
    currentLanguage,
    changeLanguage,
    isRTL,
    userData,
    isLoading
  };

  return (
    <LanguageContext.Provider value={contextValue}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);