import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaSearch, FaTimes, FaUniversity, FaGraduationCap, FaChevronRight } from 'react-icons/fa';
import styled, { keyframes, css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../contexts/LanguageContext';

// Animations
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;

const shimmer = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(37, 99, 235, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(37, 99, 235, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(37, 99, 235, 0);
  }
`;

const SearchWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 400px;
  z-index: 1000;
  
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const SearchForm = styled.form`
  width: 100%;
  position: relative;
  transition: all 0.3s ease;
   margin: 0 auto;
  margin-top: -5px;
  margin-bottom: auto;
  &:focus-within {
    transform: scale(1.02);
  }
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 12px 40px 12px 40px;
  border: 2px solid #e2e8f0;
  border-radius: 50px;
  background-color: #f8fafc;
  font-size: 14px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  color: #334155;
  
  &::placeholder {
    color: #94a3b8;
    transition: all 0.3s ease;
  }
  
  &:focus {
    outline: none;
    border-color: #2563eb;
    background-color: white;
    box-shadow: 0 0 0 4px rgba(37, 99, 235, 0.15);
    
    &::placeholder {
      opacity: 0.7;
      transform: translateX(5px);
    }
  }
  
  &:hover:not(:focus) {
    border-color: #93c5fd;
    background-color: #f9fafb;
  }
     @media (max-width: 768px) {
    padding: 10px 36px 10px 36px;
    height: 40px;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 37%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2563eb;
  transition: all 0.3s ease;
  font-size: 16px;
`;

const SearchIcon = styled(IconWrapper)`
  left: 14px;
  
  ${SearchForm}:focus-within & {
    animation: ${pulse} 2s infinite;
    color: #1d4ed8;
  }
`;

const ClearButton = styled.button`
  position: absolute;
  right: 10px;
  top: 37%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #94a3b8;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 50%;
  transition: all 0.2s ease;
  
  &:hover {
    color: #64748b;
    background-color: #f1f5f9;
  }
  
  &:active {
    transform: translateY(-50%) scale(0.95);
  }
`;

const SuggestionBox = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 8px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.1);
  max-height: 380px;
  overflow-y: auto;
  z-index: 1000;
  border: 1px solid #e2e8f0;
  animation: ${fadeIn} 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  overflow-x: hidden;
  
  /* Hide scrollbar but keep functionality */
  scrollbar-width: thin;
  scrollbar-color: #cbd5e1 #f1f5f9;
  
  &::-webkit-scrollbar {
    width: 6px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f5f9;
    border-radius: 0 12px 12px 0;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: #cbd5e1;
    border-radius: 20px;
  }
  
  ${props => props.isExiting && css`
    animation: ${fadeOut} 0.2s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  `}
`;

const SuggestionGroup = styled.div`
  padding: 0;
`;

const SuggestionHeader = styled.div`
  padding: 10px 16px;
  font-size: 12px;
  font-weight: 600;
  color: #64748b;
  background-color: #f8fafc;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Suggestion = styled.button`
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  border-bottom: 1px solid #f1f5f9;
  padding: 14px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #334155;
  font-size: 14px;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
  
  &:hover {
    background-color: #f1f9ff;
    padding-left: 20px;
  }
  
  &:active {
    background-color: #e0f2fe;
    transform: scale(0.99);
  }
  
  &:last-child {
    border-bottom: none;
  }
  
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0;
    background-color: #2563eb;
    opacity: 0.1;
    transition: width 0.3s ease;
  }
  
  &:hover::after {
    width: 5px;
  }
`;

const SuggestionIcon = styled.span`
  display: inline-flex;
  color: #2563eb;
  margin-right: 12px;
  font-size: 16px;
  opacity: 0.8;
  transition: all 0.3s ease;
  
  ${Suggestion}:hover & {
    transform: scale(1.2);
    opacity: 1;
  }
`;

const SuggestionContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const SuggestionTitle = styled.span`
  font-weight: 600;
  font-size: 14px;
  color: #334155;
  transition: color 0.2s ease;
  
  ${Suggestion}:hover & {
    color: #2563eb;
  }
`;

const SuggestionSubtitle = styled.span`
  font-size: 12px;
  color: #64748b;
  margin-top: 2px;
`;

const SuggestionArrow = styled.span`
  color: #cbd5e1;
  margin-left: 8px;
  transition: all 0.2s ease;
  opacity: 0;
  transform: translateX(-5px);
  
  ${Suggestion}:hover & {
    opacity: 1;
    transform: translateX(0);
    color: #2563eb;
  }
`;

const SeeAllButton = styled.button`
  width: 100%;
  text-align: center;
  background: #f8fafc;
  border: none;
  border-top: 1px solid #e2e8f0;
  padding: 12px;
  color: #2563eb;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  
  &:hover {
    background-color: #eff6ff;
  }
  
  &:active {
    background-color: #dbeafe;
    transform: scale(0.98);
  }
`;

const NoResults = styled.div`
  padding: 20px;
  text-align: center;
  color: #64748b;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  
  svg {
    font-size: 24px;
    color: #94a3b8;
    opacity: 0.6;
  }
`;

const HighlightText = styled.span`
  background: linear-gradient(90deg, #3b82f6, #2563eb, #3b82f6);
  background-size: 200% 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: ${shimmer} 2s linear infinite;
  font-weight: 600;
`;

/**
 * Enhanced header search component with animations and improved design
 */
const HeaderSearch = ({ universities = [] }) => {
  const [query, setQuery] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isExitingSuggestions, setIsExitingSuggestions] = useState(false);
  const [results, setResults] = useState({ universities: [], majors: [] });
  const inputRef = useRef(null);
  const suggestionsRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { isRTL } = useLanguage();
  
  // Search function
  useEffect(() => {
    if (query.trim().length > 1 && universities.length > 0) {
      const searchText = query.toLowerCase();
      const uniResults = [];
      const majorResults = [];
      
      // Find matching universities and majors
      universities.forEach(uni => {
        if (uni.name.toLowerCase().includes(searchText)) {
          uniResults.push({
            id: uni.id,
            name: uni.name,
            city: uni.city || '',
            country: uni.country || 'Egypt'
          });
        }
        
        // Check majors if available
        if (uni.majors && Array.isArray(uni.majors)) {
          uni.majors.forEach(major => {
            if (major.name.toLowerCase().includes(searchText)) {
              majorResults.push({
                universityId: uni.id,
                universityName: uni.name,
                majorName: major.name,
                degree: major.degree || 'Degree Program'
              });
            }
          });
        }
      });
      
      // Limit results to top 5 each
      setResults({
        universities: uniResults.slice(0, 5),
        majors: majorResults.slice(0, 5)
      });
    } else {
      setResults({ universities: [], majors: [] });
    }
  }, [query, universities]);
  
  // Handle clicks outside
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        suggestionsRef.current && 
        !suggestionsRef.current.contains(e.target) &&
        inputRef.current && 
        !inputRef.current.contains(e.target)
      ) {
        // Hide immediately when clicking outside
        setShowSuggestions(false);
        setIsExitingSuggestions(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);
  
  // Handle closing suggestions with animation (for programmatic closing)
  const handleCloseSuggestions = () => {
    if (showSuggestions) {
      setIsExitingSuggestions(true);
      setTimeout(() => {
        setShowSuggestions(false);
        setIsExitingSuggestions(false);
      }, 200); // Duration of the fadeOut animation
    }
  };
  
  // Handle search form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (query.trim()) {
      navigate(`/?search=${encodeURIComponent(query.trim())}`);
      handleCloseSuggestions();
      inputRef.current?.blur();
    }
  };
  
  // Handle suggestion click
  const handleSuggestionClick = (path) => {
    // Use navigate instead of direct location change for better SPA behavior
    navigate(path);
    handleCloseSuggestions();
    inputRef.current?.blur();
  };
  
  // Clear search input
  const clearSearch = () => {
    setQuery('');
    handleCloseSuggestions();
    inputRef.current?.focus();
  };
  
  // Check if we have any results
  const hasResults = results.universities.length > 0 || results.majors.length > 0;
  
  return (
    <SearchWrapper className={`header-search ${isRTL ? 'rtl' : ''}`}>
      <SearchForm onSubmit={handleSubmit}>
        <SearchIcon>
          <FaSearch />
        </SearchIcon>
        <SearchInput
          ref={inputRef}
          type="text"
          placeholder={t('search.university')}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onFocus={() => {
            if (query.trim().length > 1) {
              setShowSuggestions(true);
            }
          }}
          onKeyDown={(e) => {
            // Show suggestions when user starts typing
            if (!showSuggestions && query.trim().length > 0) {
              setShowSuggestions(true);
            }
          }}
        />
        {query && (
          <ClearButton 
            type="button" 
            onClick={clearSearch} 
            aria-label={t('clear_search')}
          >
            <FaTimes />
          </ClearButton>
        )}
      </SearchForm>
      
      {showSuggestions && query.trim().length > 1 && (
        <SuggestionBox 
          ref={suggestionsRef}
          isExiting={isExitingSuggestions}
        >
          {!hasResults && (
            <NoResults>
              <FaSearch />
              <div>
                {t('no_results')} <HighlightText>{query}</HighlightText>
              </div>
            </NoResults>
          )}
          
          {results.universities.length > 0 && (
            <SuggestionGroup>
              <SuggestionHeader>
                <FaUniversity />
                {t('universities')}
              </SuggestionHeader>
              
              {results.universities.map(uni => (
                <Suggestion 
                  key={`uni-${uni.id}`}
                  onClick={() => handleSuggestionClick(`/university/${uni.id}`)}
                >
                  <SuggestionIcon>
                    <FaUniversity />
                  </SuggestionIcon>
                  
                  <SuggestionContent>
                    <SuggestionTitle>{uni.name}</SuggestionTitle>
                    {uni.city && (
                      <SuggestionSubtitle>
                        {uni.city}, {uni.country}
                      </SuggestionSubtitle>
                    )}
                  </SuggestionContent>
                  
                  <SuggestionArrow>
                    <FaChevronRight />
                  </SuggestionArrow>
                </Suggestion>
              ))}
            </SuggestionGroup>
          )}
          
          {results.majors.length > 0 && (
            <SuggestionGroup>
              <SuggestionHeader>
                <FaGraduationCap />
                {t('majors')}
              </SuggestionHeader>
              
              {results.majors.map((major, idx) => (
                <Suggestion 
                  key={`major-${idx}`}
                  onClick={() => handleSuggestionClick(`/university/${major.universityId}/majors/${encodeURIComponent(major.majorName)}`)}
                >
                  <SuggestionIcon>
                    <FaGraduationCap />
                  </SuggestionIcon>
                  
                  <SuggestionContent>
                    <SuggestionTitle>{major.majorName}</SuggestionTitle>
                    <SuggestionSubtitle>
                      {major.degree} {t('at')} {major.universityName}
                    </SuggestionSubtitle>
                  </SuggestionContent>
                  
                  <SuggestionArrow>
                    <FaChevronRight />
                  </SuggestionArrow>
                </Suggestion>
              ))}
            </SuggestionGroup>
          )}
          
          {hasResults && (
            <SeeAllButton 
              onClick={() => handleSuggestionClick(`/?search=${encodeURIComponent(query)}`)}
            >
              {t('see_all_results')} <FaSearch />
            </SeeAllButton>
          )}
        </SuggestionBox>
      )}
    </SearchWrapper>
  );
};

export default HeaderSearch;