// src/api.js
import { Client, Storage } from 'appwrite';

const client = new Client();
client.setEndpoint('https://cloud.appwrite.io/v1')
    .setProject('6748fa610024d013c88b');

const storage = new Storage(client);
export const fetchUniversities2 = async () => {
  try {
    const bucketId = '1'; // Replace with your bucket ID
    const files = await storage.listFiles(bucketId); // List all files in the bucket

    const universities = await Promise.all(
        files.files.map(async (file) => {
          const fileUrl = storage.getFileView(bucketId, file.$id); // Get file URL
          const response = await fetch(fileUrl);
          return response.json(); // Parse JSON data
        })
    );

    return universities;
  } catch (error) {
    console.error('Error fetching universities from bucket:', error);
    throw error;
  }
};
export const fetchUniversities = async () => {
  try {
    const bucketId = '1'; // Your bucket ID
    const files = await storage.listFiles(bucketId);

    console.log(`Found ${files.files.length} files in bucket. Processing...`);
    
    const universitiesPromises = files.files.map(async (file) => {
      try {
        try {
          // For browser environment, Appwrite SDK handles the download
          // and returns the data directly, no need for additional parsing
          const universityData = await storage.getFileDownload(bucketId, file.$id);
          
          // If we get a raw blob or unexpected data format, handle it
          if (universityData instanceof Blob) {
            // Handle blob data
            const text = await new Response(universityData).text();
            return JSON.parse(text);
          }
          
          // If we already have a parsed JS object
          if (typeof universityData === 'object' && universityData !== null) {
            return universityData;
          }
          
          // If we got a string, parse it
          if (typeof universityData === 'string') {
            return JSON.parse(universityData);
          }
          
          console.warn(`File ${file.$id} returned unexpected data type:`, typeof universityData);
          return null;
        } catch (downloadError) {
          console.warn(`Error downloading file ${file.$id}: ${downloadError.message}`);
          return null;
        }
      } catch (error) {
        console.warn(`Error processing file ${file.$id}: ${error.message}`);
        return null;
      }
    });

    const universities = await Promise.all(universitiesPromises);
    const validUniversities = universities.filter(uni => uni !== null);
    
    console.log(`Successfully fetched ${validUniversities.length} universities (${universities.length - validUniversities.length} files skipped)`);
    
    return validUniversities;
  } catch (error) {
    console.error('Error fetching universities from bucket:', error);
    throw error;
  }
  
};