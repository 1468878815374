import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaQuestionCircle, FaTimes } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../contexts/LanguageContext';

// Animation keyframes
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const scaleIn = keyframes`
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const scaleOut = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0;
  }
`;

// Modal overlay that covers the entire screen
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  animation: ${props => props.isClosing ? fadeOut : fadeIn} 0.3s ease-out forwards;
`;

// Modal card with animation
const ModalCard = styled.div`
  background-color: white;
  border-radius: 16px;
  padding: 28px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 20px rgba(37, 99, 235, 0.15);
  position: relative;
  animation: ${props => props.isClosing ? scaleOut : scaleIn} 0.3s ease-out forwards;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 12px;
  right: 12px;
  background: transparent;
  border: none;
  font-size: 16px;
  color: #64748b;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 50%;
  transition: all 0.2s ease;
  
  &:hover {
    background: #f1f5f9;
    color: #2563eb;
  }
`;

const ModalTitle = styled.h2`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 24px;
  font-weight: 600;
  color: #2563eb;
  margin-bottom: 20px;
`;

const ConfirmIcon = styled(FaQuestionCircle)`
  font-size: 24px;
  color: #2563eb;
`;

const ModalContent = styled.div`
  color: #334155;
  line-height: 1.6;
  margin-bottom: 24px;
  
  p {
    margin-bottom: 16px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

const ModalButton = styled.button`
  background-color: ${props => props.primary ? '#2563eb' : '#f8fafc'};
  color: ${props => props.primary ? 'white' : '#64748b'};
  border: ${props => props.primary ? 'none' : '1px solid #e2e8f0'};
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: ${props => props.primary ? '#1d4ed8' : 'white'};
    transform: ${props => props.primary ? 'translateY(-2px)' : 'none'};
    box-shadow: ${props => props.primary ? '0 4px 12px rgba(37, 99, 235, 0.2)' : 'none'};
  }
  
  @media (max-width: 480px) {
    padding: 10px 20px;
    font-size: 14px;
  }
`;

const ConfirmationModal = ({ 
  isOpen, 
  onClose, 
  onConfirm, 
  title, 
  message,
  confirmText,
  cancelText
}) => {
  const { t } = useTranslation();
  const { isRTL } = useLanguage();
  const [isClosing, setIsClosing] = useState(false);

  // Default values with translations
  const modalTitle = title || t('confirmationModal.defaultTitle');
  const modalMessage = message || t('confirmationModal.defaultMessage');
  const modalConfirmText = confirmText || t('confirmationModal.confirm');
  const modalCancelText = cancelText || t('confirmationModal.cancel');

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, 300); // Match this with animation duration
  };

  const handleConfirm = () => {
    setIsClosing(true);
    setTimeout(() => {
      onConfirm();
      onClose();
      setIsClosing(false);
    }, 300);
  };

  // Close on ESC key
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) handleClose();
    };
    
    if (isOpen) {
      window.addEventListener('keydown', handleEsc);
    }
    
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [isOpen]);

  // Don't render if not open
  if (!isOpen) return null;

  return (
    <ModalOverlay isClosing={isClosing} onClick={handleClose} className={isRTL ? 'rtl' : ''}>
      <ModalCard isClosing={isClosing} onClick={e => e.stopPropagation()}>
        <CloseButton onClick={handleClose} aria-label={t('confirmationModal.aria.close')}>
          <FaTimes />
        </CloseButton>
        
        <ModalTitle>
          <ConfirmIcon />
          {modalTitle}
        </ModalTitle>
        
        <ModalContent>
          <p>{modalMessage}</p>
        </ModalContent>
        
        <ButtonContainer>
          <ModalButton onClick={handleClose}>
            {modalCancelText}
          </ModalButton>
          <ModalButton primary onClick={handleConfirm}>
            {modalConfirmText}
          </ModalButton>
        </ButtonContainer>
      </ModalCard>
    </ModalOverlay>
  );
};

export default ConfirmationModal;