import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import logo from '../assets/logo.webp';
import { useTranslation } from 'react-i18next';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #2563eb 0%,rgb(170, 207, 252) 50%,rgb(147, 192, 247) 100%);
  animation: ${props => props.fadeOut ? fadeOut : fadeIn} 0.5s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  opacity: ${props => props.fadeOut ? 0 : 1};
  pointer-events: ${props => props.fadeOut ? 'none' : 'auto'};
  transition: opacity 0.5s ease-in-out;
`;

const LogoWrapper = styled.div`
  margin-bottom: 30px;
  animation: ${props => props.pulse ? 'pulse 2s infinite' : 'none'};

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }

  img {
    height: 120px;
    width: auto;
  }
`;

const LoadingText = styled.h2`
  color: #2c3e50;
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0;
  opacity: 0.9;
`;

const Loading = () => {
  const { t } = useTranslation();
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    // Start fade out animation after 2 seconds
    const timer = setTimeout(() => {
      setFadeOut(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <LoadingContainer fadeOut={fadeOut}>
      <LogoWrapper pulse>
        <img src={logo} alt="EDU Finders Logo" />
      </LogoWrapper>
      <LoadingText>{t('loading.message')}</LoadingText>
    </LoadingContainer>
  );
};

export default Loading;