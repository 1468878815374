import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { validateTranslations } from '../../utils/translationValidator';

const Container = styled.div`
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 9999;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 350px;
  max-height: 500px;
  overflow: auto;
  padding: 10px;
  font-family: monospace;
  font-size: 12px;
`;

const Button = styled.button`
  position: fixed;
  bottom: 10px;
  right: 10px;
  background: #4CAF50;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 5px;
  z-index: 9999;
  
  &:hover {
    background: #45a049;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #333;
`;

const MissingKey = styled.div`
  margin-bottom: 5px;
  padding: 5px;
  border-left: 3px solid ${props => props.type === 'missing-key' ? '#f44336' : '#ff9800'};
  background: ${props => props.type === 'missing-key' ? '#ffebee' : '#fff8e1'};
`;

const KeyName = styled.div`
  font-weight: bold;
`;

const KeyValue = styled.div`
  color: #555;
  margin-top: 3px;
`;

const TranslationChecker = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [translationStatus, setTranslationStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  
  const checkTranslations = async () => {
    setLoading(true);
    try {
      const status = await validateTranslations();
      setTranslationStatus(status);
    } catch (error) {
      console.error('Error checking translations:', error);
      setTranslationStatus({ 
        missingInArabic: [], 
        totalMissing: 0, 
        error: error.message 
      });
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    if (isOpen) {
      checkTranslations();
    }
  }, [isOpen]);
  
  if (!isOpen) {
    return (
      <Button onClick={() => setIsOpen(true)}>
        Check Translations
      </Button>
    );
  }
  
  return (
    <Container>
      <CloseButton onClick={() => setIsOpen(false)}>×</CloseButton>
      <h3>Translation Status</h3>
      
      {loading ? (
        <div>Loading translations...</div>
      ) : translationStatus?.error ? (
        <div style={{ color: 'red' }}>Error: {translationStatus.error}</div>
      ) : (
        <>
          <div>
            Missing in Arabic: {translationStatus?.totalMissing || 0}
          </div>
          <div style={{ marginTop: 10 }}>
            {translationStatus?.missingInArabic.map((item, index) => (
              <MissingKey key={index} type={item.type}>
                <KeyName>{item.key}</KeyName>
                <KeyValue>EN: "{item.enValue}"</KeyValue>
                {item.type === 'untranslated' && (
                  <KeyValue>AR: "{item.arValue}" (untranslated)</KeyValue>
                )}
              </MissingKey>
            )).slice(0, 20)}
            {translationStatus?.missingInArabic && translationStatus.missingInArabic.length > 20 && (
              <div>...and {translationStatus.missingInArabic.length - 20} more</div>
            )}
          </div>
        </>
      )}
    </Container>
  );
};

export default TranslationChecker;