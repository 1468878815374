import React, { useState, useEffect } from 'react';
import { Storage } from 'appwrite';
import { client } from '../utils/appwriteConfig'; // Adjust path as needed

const storage = new Storage(client);

const Appwritepfp = ({ bucketId, fileId, alt, className, style, fallback }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [error, setError] = useState(false);
  
  useEffect(() => {
    if (!bucketId || !fileId) {
      setError(true);
      return;
    }
    
    try {
      // Direct approach using preview URL with width/height
      const imageUrl = storage.getFilePreview(
        bucketId, 
        fileId,
        200, // width
        200, // height
        'center', // gravity
        100 // quality
      );
      
      setImageSrc(imageUrl);
    } catch (err) {
      console.error('Error getting image preview:', err);
      setError(true);
    }
  }, [bucketId, fileId]);
  
  if (error && fallback) {
    return fallback;
  }
  
  if (error) {
    return (
      <div 
        className={className}
        style={{
          backgroundColor: '#2563eb', 
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontWeight: 'bold',
          ...style
        }}
      >
        {alt ? alt.charAt(0).toUpperCase() : 'U'}
      </div>
    );
  }
  
  return (
    <img
      src={imageSrc}
      alt={alt}
      className={className}
      style={style}
      crossOrigin="anonymous"
      onError={() => setError(true)}
    />
  );
};

export default Appwritepfp;