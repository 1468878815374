import React, { useEffect, useState, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate, Navigate } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import Header from './components/Header';
import Footer from './components/Footer';
import Loading from './components/Loading';
import ScrollToTop from './components/ScrollToTop';
import { fetchUniversities2 } from './api';
import { Client, Account as AppwriteAccount } from 'appwrite';
import styled from 'styled-components';
import './components/BackgroundPattern.css';
import './i18n'; // Import the i18n configuration
import { LanguageProvider } from './contexts/LanguageContext';
import { validateTranslations, enableTranslationDebugging } from './utils/translationValidator';
import TranslationChecker from './components/dev/TranslationChecker';
import './App.css';
import './rtl.css'; // Make sure this is imported

// Code-split components using lazy loading
const UniversityList = lazy(() => import('./components/UniversityList'));
const UniversityDetail = lazy(() => import('./components/UniversityDetail'));
const NotFound = lazy(() => import('./components/NotFound'));
const Login = lazy(() => import('./components/login'));
const ForgotPassword = lazy(() => import('./components/ForgotPassword'));
const ResetPassword = lazy(() => import('./components/ResetPassword'));
const CreateAccount = lazy(() => import('./components/CreateAccount'));
const VerifyEmail = lazy(() => import('./components/VerifyEmail'));
const About = lazy(() => import('./components/About'));
const MajorDetail = lazy(() => import('./components/MajorDetail'));
const Favorites = lazy(() => import('./components/Favorites'));
const AccountPage = lazy(() => import('./components/Account'));
const AdminPanel = lazy(() => import('./components/AdminPanel'));
const ContactUs = lazy(() => import('./components/ContactUs'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const TermsOfService = lazy(() => import('./components/TermsOfService'));

// Create and configure the Appwrite client
const client = new Client();
client.setEndpoint('https://cloud.appwrite.io/v1')
    .setProject('6748fa610024d013c88b');

// Enhanced cross-origin image handling
if (typeof window !== 'undefined') {
  // Apply crossOrigin attribute to all image elements created after this code
  const originalCreateElement = document.createElement;
  
  document.createElement = function(tagName) {
    const element = originalCreateElement.apply(document, arguments);
    
    if (tagName.toLowerCase() === 'img') {
      // Set crossOrigin for all images by default
      // The browser will only apply it when actually needed
      element.crossOrigin = 'anonymous';
    }
    
    return element;
  };
  
  // Patch Image constructor to set crossOrigin by default
  const OriginalImage = window.Image;
  window.Image = function(...args) {
    const image = new OriginalImage(...args);
    image.crossOrigin = 'anonymous';
    return image;
  };
  
  // Patch the Image prototype's src property
  const originalImageSrc = Object.getOwnPropertyDescriptor(HTMLImageElement.prototype, 'src');
  
  Object.defineProperty(HTMLImageElement.prototype, 'src', {
    get: function() {
      return originalImageSrc.get.call(this);
    },
    set: function(value) {
      // Set crossOrigin for all Appwrite URLs
      if (value && typeof value === 'string' && 
         (value.includes('cloud.appwrite.io') || value.includes('appwrite'))) {
        this.crossOrigin = 'anonymous';
      }
      return originalImageSrc.set.call(this, value);
    }
  });
}

const account = new AppwriteAccount(client);

// Protected Route Component
const ProtectedRoute = ({ children, adminOnly = false }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const session = await account.getSession('current');
                const user = await account.get();
                
                // Check if user has admin label
                setIsAdmin(user.labels.includes('admin'));
                
                setIsAuthenticated(!!session);
            } catch (error) {
                setIsAuthenticated(false);
                setIsAdmin(false);
            } finally {
                setIsLoading(false);
            }
        };

        checkAuth();
    }, []);

    if (isLoading) {
        return <Loading />; // Use your existing Loading component
    }

    // If route requires admin and user is not admin
    if (adminOnly && !isAdmin) {
        return <Navigate to="/404" replace state={{ from: location }} />;
    }

    // If user is not authenticated
    if (!isAuthenticated) {
        return <Navigate to="/login" replace state={{ from: location }} />;
    }

    return children;
};

// Loading wrapper component for Suspense fallback
const LoadingWrapper = () => {
  return <Loading />;
};

function App() {
  const [universities, setUniversities] = useState([]);
  const [error, setError] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [translationStatus, setTranslationStatus] = useState(null);

  useEffect(() => {
    // Add the pattern element directly to the document body
    const patternDiv = document.createElement('div');
    patternDiv.style.position = 'fixed';
    patternDiv.style.top = '0';
    patternDiv.style.left = '0';
    patternDiv.style.right = '0';
    patternDiv.style.bottom = '0';
    // SVG with reduced opacity
    patternDiv.style.backgroundImage = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='60' viewBox='0 0 24 24' fill='none' stroke='rgba(0, 81, 255, 0.05)' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M22 10v6M2 10l10-5 10 5-10 5z'%3E%3C/path%3E%3Cpath d='M6 12v5c0 2 2 3 6 3s6-1 6-3v-5'%3E%3C/path%3E%3C/svg%3E")`;
    patternDiv.style.backgroundSize = '60px 60px';
    patternDiv.style.zIndex = '0';
    patternDiv.style.pointerEvents = 'none';
    
    // Add rotation to match the gradient angle (135 degrees)
    patternDiv.style.transform = 'rotate(-45deg)';
    
    // Make the pattern a bit larger and position it to cover the entire viewport even when rotated
    patternDiv.style.width = '200%';
    patternDiv.style.height = '200%';
    patternDiv.style.left = '-50%';
    patternDiv.style.top = '-50%';
    
    // Insert as the first child of body
    document.body.insertBefore(patternDiv, document.body.firstChild);
    
    // Clean up when component unmounts
    return () => {
      document.body.removeChild(patternDiv);
    };
  }, []);

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        const cachedSession = localStorage.getItem('userSession');
        if (cachedSession) {
          setCurrentUser(JSON.parse(cachedSession));
        } else {
          const session = await account.get();
          setCurrentUser(session);
          localStorage.setItem('userSession', JSON.stringify(session));
        }
      } catch (error) {
        console.error('No active session:', error);
        setCurrentUser(null);
      } finally {
        setAuthLoading(false);
      }
    };

    initializeAuth();
  }, []);

  useEffect(() => {
    const loadUniversities = async () => {
      try {
        const cachedUniversities = localStorage.getItem('universities');
        if (cachedUniversities) {
          setUniversities(JSON.parse(cachedUniversities));
        } else {
          const universitiesData = await fetchUniversities2();
          setUniversities(universitiesData);
          localStorage.setItem('universities', JSON.stringify(universitiesData));
          const oneDay = 1 * 60 * 60 * 1000; // 24 hours in milliseconds
          localStorage.setItem('universities_expiration', oneDay);
        }
      } catch (error) {
        console.error('Error fetching universities:', error);
        setError('Failed to load universities.');
      }
    };

    loadUniversities();
  }, []);

  useEffect(() => {
    if (!authLoading) {
      setTimeout(() => {
        setFadeOut(true);
      }, 100);

      setTimeout(() => setShowSpinner(false), 900);
    }
  }, [authLoading]);

  useEffect(() => {
    // Only in development mode
    if (process.env.NODE_ENV === 'development') {
      // Enable visual indicators for missing translations
      enableTranslationDebugging();
      
      // Check for missing translations and log them
      const status = validateTranslations();
      setTranslationStatus(status);
      console.log('Translation status:', status);
    }
  }, []);

  if (showSpinner) {
    return <Loading />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  
  return (
    <HelmetProvider>
      {/* Default SEO configuration that applies to all pages */}
      <Helmet>
        <title>EDU Finders - Find the Best Universities in Egypt</title>
        <meta name="description" content="EDU Finders helps you find the best universities in Egypt with detailed information on programs, fees, rankings, and facilities." />
        <meta name="keywords" content="universities, egypt, education, college, higher education, majors, degrees, university finder" />
        <meta name="author" content="EDU Finders" />
        
        {/* OpenGraph tags */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://edufinders.com/" />
        <meta property="og:title" content="EDU Finders - Find the Best Universities in Egypt" />
        <meta property="og:description" content="EDU Finders helps you find the best universities in Egypt with detailed information on programs, fees, and facilities." />
        <meta property="og:image" content="/logo1560.png" />
        <meta property="og:site_name" content="EDU Finders" />
        
        {/* Twitter Card tags */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://edufinders.com/" />
        <meta property="twitter:title" content="EDU Finders - Find the Best Universities in Egypt" />
        <meta property="twitter:description" content="EDU Finders helps you find the best universities in Egypt with detailed information on programs, fees, and facilities." />
        <meta property="twitter:image" content="/logo1560.png" />
        
        {/* Add canonical URL for homepage by default */}
        <link rel="canonical" href="https://edufinders.com/" />
        
        {/* Structured data for the website */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "EDU Finders",
            "url": "https://edufinders.com",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://edufinders.com?search={search_term_string}",
              "query-input": "required name=search_term_string"
            },
            "description": "Find the best universities in Egypt with comprehensive information on programs, fees, rankings, and facilities.",
            "publisher": {
              "@type": "Organization",
              "name": "EDU Finders",
              "logo": {
                "@type": "ImageObject",
                "url": "https://edufinders.com/logo1560.png"
              }
            }
          }
        `}</script>
      </Helmet>

      {/* Wrap the entire application with Suspense for code-splitting */}
      <Suspense fallback={<LoadingWrapper />}>
        <div className="App">
          <Loading />
          <Router>
            <LanguageProvider>
          
              <AppContent
                universities={universities}
                currentUser={currentUser}
                authLoading={authLoading}
              />
  
            </LanguageProvider>
          </Router>
        </div>
      </Suspense>
    </HelmetProvider>
  );
}

function AppContent({ universities, currentUser, authLoading }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [isTransitioning, setIsTransitioning] = useState(false);
  
  // Hide header and footer on auth-related pages
  const hideHeaderAndFooter = [
    '/login', 
    '/forgot-password', 
    '/reset-password', 
    '/create-account', 
    '/verify-email'
  ].includes(location.pathname);

  useEffect(() => {
    // Only trigger transitions when the actual route changes
    // This prevents loading screens from appearing on modal opens
    const pathname = location.pathname;
    const search = location.search;
    
    setIsTransitioning(true);
    const transitionTimer = setTimeout(() => {
      setIsTransitioning(false);
    }, 2500);
    
    return () => clearTimeout(transitionTimer);
  }, [location.pathname, location.search]); // Only depend on actual route changes

  const BackgroundWrapper = styled.div`
    position: fixed;
  `;

  return (
    <>
      {isTransitioning && <Loading />}
      <div className="pattern-background">
      <div style={{ 
        opacity: isTransitioning ? 0 : 1,
        transition: 'opacity 0.5s ease-in-out',
        visibility: isTransitioning ? 'hidden' : 'visible',
        paddingTop: hideHeaderAndFooter ? 0 : undefined
      }}>
        <BackgroundWrapper />
        {!hideHeaderAndFooter && <Header />}
        <ScrollToTop />
        <Routes>
          {/* Auth Routes */}
          <Route path="/login" element={
            <Suspense fallback={<LoadingWrapper />}>
              {currentUser ? <Navigate to="/" replace /> : <Login />}
            </Suspense>
          } />
          <Route path="/forgot-password" element={
            <Suspense fallback={<LoadingWrapper />}>
              <ForgotPassword />
            </Suspense>
          } />
          <Route path="/reset-password" element={
            <Suspense fallback={<LoadingWrapper />}>
              <ResetPassword />
            </Suspense>
          } />
          <Route path="/create-account" element={
            <Suspense fallback={<LoadingWrapper />}>
              <CreateAccount />
            </Suspense>
          } />
          <Route path="/verify-email" element={
            <Suspense fallback={<LoadingWrapper />}>
              <VerifyEmail />
            </Suspense>
          } />
          
          {/* Public Legal Pages */}
          <Route path="/PrivacyPolicy" element={
            <Suspense fallback={<LoadingWrapper />}>
              <PrivacyPolicy />
            </Suspense>
          } />
          <Route path="/TermsOfService" element={
            <Suspense fallback={<LoadingWrapper />}>
              <TermsOfService />
            </Suspense>
          } />
          
          {/* Protected Routes */}
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Suspense fallback={<LoadingWrapper />}>
                  <UniversityList universities={universities} />
                </Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="/favorites"
            element={
              <ProtectedRoute>
                <Suspense fallback={<LoadingWrapper />}>
                  <Favorites universities={universities} />
                </Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path="/university/:id"
            element={
              <ProtectedRoute>
                <Suspense fallback={<LoadingWrapper />}>
                  <UniversityDetail universities={universities} />
                </Suspense>
              </ProtectedRoute>
            }
          />
          <Route 
            path="/university/:uniId/majors/:majorName" 
            element={
              <ProtectedRoute>
                <Suspense fallback={<LoadingWrapper />}>
                  <MajorDetail universities={universities} />
                </Suspense>
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/about" 
            element={
              <ProtectedRoute>
                <Suspense fallback={<LoadingWrapper />}>
                  <About />
                </Suspense>
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/contactus" 
            element={
              <ProtectedRoute>
                <Suspense fallback={<LoadingWrapper />}>
                  <ContactUs />
                </Suspense>
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/account" 
            element={
              <ProtectedRoute>
                <Suspense fallback={<LoadingWrapper />}>
                  <AccountPage />
                </Suspense>
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/admin" 
            element={
              <ProtectedRoute adminOnly={true}>
                <Suspense fallback={<LoadingWrapper />}>
                  <AdminPanel />
                </Suspense>
              </ProtectedRoute>
            } 
          />
          <Route path="*" element={
            <Suspense fallback={<LoadingWrapper />}>
              <NotFound />
            </Suspense>
          } />
        </Routes>
        {!hideHeaderAndFooter && (
          <div className="footer-color">
            <Footer />
          </div>
        )}
      </div>
      </div>
    </>
  );
}

export default App;