import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Client, Account, Storage } from 'appwrite';
import './Header.css';
import logo from '../assets/logo.webp';
import { 
  FaSignOutAlt, FaBroom, FaUser, FaInfoCircle, 
  FaEnvelope, FaHeart, FaCog, FaBars, FaTimes, 
  FaTools, FaShieldAlt, FaFlask
} from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';
import WelcomeModal from './WelcomeModal';
import ConfirmationModal from './ConfirmationModal';
 
import { loadImageSafely, createInitialsPlaceholder } from '../utils/imageHelper';
import Appwritepfp from '../utils/appwritepfp';
import HeaderSearch from './HeaderSearch'; // Import the new search component
import { useTranslation } from 'react-i18next';
// Import the LanguageSwitcher component
import { useLanguage } from '../contexts/LanguageContext';
import LanguageSelector from './LanguageSelector';

const client = new Client();
client.setEndpoint('https://cloud.appwrite.io/v1')
    .setProject('6748fa610024d013c88b');

const account = new Account(client);
const storage = new Storage(client);

// Profile image bucket ID 
const BUCKET_ID = 'profile_images';

// Pulsate animation for the beta badge
const pulsate = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(52, 152, 219, 0.7);
  }
  
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(52, 152, 219, 0);
  }
  
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(52, 152, 219, 0);
  }
`;

// Styled components for the beta badge
const BetaBadge = styled.div`
  background: linear-gradient(135deg, #2563eb 0%, #60a5fa 100%);
  color: white;
  padding: 3px 8px;
  border-radius: 4px;
  font-size: 11px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: 10px;
  cursor: pointer;
  animation: ${pulsate} 2s infinite;
  box-shadow: 0 0 0 0 rgba(52, 152, 219, 0.7);
  transition: all 0.2s ease;
  
  &:hover {
    transform: scale(1.1);
    background-color: #2980b9;
  }
`;

const BetaIcon = styled(FaFlask)`
  font-size: 10px;
`;

const MobileSearchContainer = styled.div`
  padding: 10px 16px;
  border-bottom: 1px solid #e0f2fe;
`;

const Header = () => {
  const [user, setUser] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(false);
  const [universities, setUniversities] = useState([]);
  const { t } = useTranslation();
  const { isRTL } = useLanguage();
  
  // Confirmation modal states
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalData, setConfirmModalData] = useState({
    title: "",
    message: "",
    onConfirm: () => {},
    confirmText: "Confirm"
  });
  
  const dropdownRef = useRef(null);
  const mobileMenuRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await account.get();
        setUser(userData);
        
        // Check if user has admin label
        setIsAdmin(userData.labels?.includes('admin') || false);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setUser(null);
        setIsAdmin(false);
      }
    };
    
    fetchUserData();

    // Load universities from localStorage for search function
    const cachedUniversities = localStorage.getItem('universities');
    if (cachedUniversities) {
      setUniversities(JSON.parse(cachedUniversities));
    }

    // Check if this is the first visit to show the beta modal
    const hasSeenWelcomeModal = localStorage.getItem('hasSeenWelcomeModal');
    if (!hasSeenWelcomeModal) {
      // Set a small delay to ensure it shows after the page finishes loading
      setTimeout(() => {
        setIsWelcomeModalOpen(true);
      }, 1500);
    }
  }, []);

  const confirmLogout = () => {
    setConfirmModalData({
      title: "Sign Out",
      message: "Are you sure you want to sign out?",
      onConfirm: handleLogout,
      confirmText: "Sign Out"
    });
    setConfirmModalOpen(true);
  };

  const confirmClearCache = () => {
    setConfirmModalData({
      title: "Clear Cache",
      message: "Are you sure you want to clear all cached data? This will reset your favorites, other local data, and refresh your University Data.",
      onConfirm: clearCache,
      confirmText: "Clear Cache"
    });
    setConfirmModalOpen(true);
  };

  const handleLogout = async () => {
    try {
      await account.deleteSession('current');
      localStorage.removeItem('userSession');
      
      // Redirect to login and then refresh the page
      navigate('/login');
      
      // Force page refresh to apply changes immediately
      window.location.reload();
    } catch (error) {
      console.error("Logout error:", error);
      // Even if there's an error, still try to refresh
      window.location.reload();
    }
  };

  const clearCache = () => {
    localStorage.removeItem('universities');
    localStorage.removeItem('universities_timestamp');
    localStorage.removeItem('hasSeenWelcomeModal');
    localStorage.removeItem('favorites');
    console.log('Cache cleared');
    setIsDropdownOpen(false);
    setIsMobileMenuOpen(false);
    
    // Reload the page to apply changes immediately
    window.location.reload();
  };

  const closeDropdown = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsDropdownOpen(false);
      setIsClosing(false);
    }, 200); // Match this timing with the CSS animation duration
  };

  const toggleDropdown = () => {
    if (isDropdownOpen) {
      closeDropdown();
    } else {
      setIsDropdownOpen(true);
      setIsMobileMenuOpen(false); // Close mobile menu if open
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    if (isDropdownOpen) {
      closeDropdown();
    }
  };

  const handleNavLinkClick = () => {
    setIsMobileMenuOpen(false);
  };

  const handleBetaBadgeClick = (e) => {
    // Prevent any event propagation to avoid triggering navigation/transitions
    e.preventDefault();
    e.stopPropagation();
    setIsWelcomeModalOpen(true);
  };

  // Then replace the renderProfilePhoto function with this:
const renderProfilePhoto = (containerClass, showImage = true) => {
  if (user?.prefs?.profileImageId && showImage) {
    return (
      <div className={containerClass}>
        <Appwritepfp
          bucketId={BUCKET_ID}
          fileId={user.prefs.profileImageId}
          alt={user?.name || 'User'}
          className="user-image" // Add appropriate class if needed
          fallback={
            <div className={`${containerClass} profile-placeholder`}>
              <span>{user?.name?.charAt(0).toUpperCase() || 'U'}</span>
            </div>
          }
        />
      </div>
    );
  } else {
    // Show placeholder with first letter of name or default
    return (
      <div className={`${containerClass} profile-placeholder`}>
        <span>{user?.name?.charAt(0).toUpperCase() || 'U'}</span>
      </div>
    );
  }
};

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target) && 
          !event.target.closest('.mobile-menu-button')) {
        setIsMobileMenuOpen(false);
      }
    };

    if (isDropdownOpen || isMobileMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      // Prevent scrolling when mobile menu is open
      if (isMobileMenuOpen) {
        document.body.style.overflow = 'hidden';
      }
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = '';
    };
  }, [isDropdownOpen, isMobileMenuOpen]);

  return (
    <>
      <header className={`header ${isRTL ? 'rtl' : ''}`}>
        <div className="header-container">
          <div className="header-content">
            {/* Logo Section with Beta Badge */}
            <Link to="/" className="logo-link">
              <img src={logo} alt="Logo" className="logo" />
              <BetaBadge onClick={handleBetaBadgeClick} aria-label="Beta information">
                {t('header.aboutUs')}
              </BetaBadge>
            </Link>

            {/* Mobile Menu Toggle Button */}
            {user && (
              <button 
                className="mobile-menu-button" 
                onClick={toggleMobileMenu}
                aria-label="Toggle menu"
              >
                {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
              </button>
            )}

            {/* Desktop Search Bar - Now using the new HeaderSearch component */}
            {user && (
              <div className="search-bar-container">
                <HeaderSearch universities={universities} />
              </div>
            )}

            {/* Navigation Section with Icons - Desktop */}
            {user && (
              <>
                <nav className="desktop-nav">
                  <Link to="/favorites" className="nav-link">
                    <FaHeart className="nav-icon" />
                    <span>{t('header.favorites')}</span>
                  </Link>
                  <Link to="/About" className="nav-link">
                    <FaInfoCircle className="nav-icon" />
                    <span>{t('header.about')}</span>
                  </Link>
                  <Link to="/contactus" className="nav-link">
                    <FaEnvelope className="nav-icon" />
                    <span>{t('header.contact')}</span>
                  </Link>
                  <LanguageSelector />
                </nav>

                {/* Mobile Menu */}
                <div className={`mobile-menu ${isMobileMenuOpen ? 'open' : ''}`} ref={mobileMenuRef}>
                  <div className="mobile-menu-header">
                    <div className="mobile-profile-info">
                      {renderProfilePhoto("mobile-profile-photo")}
                      <div>
                        <div className="mobile-profile-name">{user?.name || t('common.user')}</div>
                        <div className="mobile-profile-email">{user?.email || ''}</div>
                      </div>
                    </div>
                    <button 
                      className="mobile-menu-close" 
                      onClick={toggleMobileMenu}
                      aria-label="Close menu"
                    >
                      <FaTimes />
                    </button>
                  </div>
                  
                  {/* Mobile search bar - Using new HeaderSearch component */}
                  <MobileSearchContainer>
                    <HeaderSearch universities={universities} />
                  </MobileSearchContainer>
                  
                  <div className="mobile-nav-links">
                    <Link to="/favorites" className="mobile-nav-link" onClick={handleNavLinkClick}>
                      <FaHeart className="nav-icon" />
                      <span>{t('header.favorites')}</span>
                    </Link>
                    <Link to="/account" className="mobile-nav-link" onClick={handleNavLinkClick}>
                      <FaCog className="nav-icon" />
                      <span>{t('header.myAccount')}</span>
                    </Link>
                    {isAdmin && (
                      <Link to="/admin" className="mobile-nav-link" onClick={handleNavLinkClick}>
                        <FaTools className="nav-icon" />
                        <span>{t('header.admin')}</span>
                      </Link>
                    )}
                    <Link to="/About" className="mobile-nav-link" onClick={handleNavLinkClick}>
                      <FaInfoCircle className="nav-icon" />
                      <span>{t('header.about')}</span>
                    </Link>
                    <Link to="/contactus" className="mobile-nav-link" onClick={handleNavLinkClick}>
                      <FaEnvelope className="nav-icon" />
                      <span>{t('header.contact')}</span>
                    </Link>
                    <LanguageSelector />
                  </div>
                  
                  <div className="mobile-menu-footer">
                    <button 
                      onClick={confirmClearCache} 
                      className="mobile-menu-button-styled"
                      aria-label="Clear cache"
                    >
                      <FaBroom className="button-icon" />
                      <span>{t('header.clearCache')}</span>
                    </button>
                    <button 
                      onClick={confirmLogout} 
                      className="mobile-menu-button-styled logout"
                      aria-label="Sign out"
                    >
                      <FaSignOutAlt className="button-icon" />
                      <span>{t('header.signOut')}</span>
                    </button>
                  </div>
                </div>
              </>
            )}

            {/* User Section - Desktop */}
            <div className="user-section">
              {user ? (
                <div className="user-dropdown" ref={dropdownRef}>
                  <button 
                    onClick={toggleDropdown}
                    className="user-button"
                    aria-label="User menu"
                  >
                    {renderProfilePhoto("profile-photo")}
                  </button>

                  {(isDropdownOpen || isClosing) && (
                    <div className={`dropdown-menu ${isClosing ? 'closing' : ''}`}>
                      <div className="dropdown-header">
                        <div className="dropdown-profile">
                          {renderProfilePhoto("dropdown-profile-photo")}
                          <div className="dropdown-profile-info">
                            <div className="dropdown-profile-name">{user?.name || t('common.user')}</div>
                            <div className="dropdown-profile-email">{user?.email || ''}</div>
                          </div>
                        </div>
                      </div>
                      
                      <div className="dropdown-divider"></div>
                      
                      <Link to="/account" className="dropdown-item" onClick={closeDropdown}>
                        <FaCog className="dropdown-icon" />
    <span>{t('header.account')}</span>
                      </Link>
                      {isAdmin && (
                        <Link to="/admin" className="dropdown-item" onClick={closeDropdown}>
                          <FaTools className="dropdown-icon" />
                          <span>{t('header.admin')}</span>
                        </Link>
                      )}
                      <button 
                        onClick={confirmClearCache} 
                        className="dropdown-item"
                        aria-label="Clear application cache"
                      >
                        <FaBroom className="dropdown-icon" />
                        <span>{t('header.clearCache')}</span>
                      </button>
                      <button 
                        onClick={confirmLogout} 
                        className="dropdown-item"
                        aria-label="Sign out of account"
                      >
                        <FaSignOutAlt className="dropdown-icon" />
                        <span>{t('header.signOut')}</span>
                      </button>
       
                    </div>
                  )}
                </div>
              ) : (
                <Link to="/login" className="sign-in-button">
                  <FaUser className="sign-in-icon" />
                  {t('header.signIn')}
                </Link>

              )}
            </div>
            <div className="right-section">
              {/* Other header elements */}
              
              {/* User profile or login button */}
            </div>
          </div>
        </div>
        
        {/* Overlay for mobile menu */}
        {isMobileMenuOpen && <div className="mobile-menu-overlay" onClick={toggleMobileMenu}></div>}
      </header>
      
      {/* Beta Alert Modal */}
      <WelcomeModal 
        isOpen={isWelcomeModalOpen} 
        onClose={() => setIsWelcomeModalOpen(false)} 
      />
      
      {/* Confirmation Modal */}
      <ConfirmationModal
        isOpen={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        onConfirm={confirmModalData.onConfirm}
        title={confirmModalData.title}
        message={confirmModalData.message}
        confirmText={confirmModalData.confirmText}
      />
    </>
  );
};

export default Header;