import React, { useState, useEffect } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import styled from 'styled-components';
import { FaGlobe, FaCheck, FaChevronDown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

// Update the LanguageContainer to ensure it handles clicks properly
const LanguageContainer = styled.div`
  position: relative;
  margin: 0 5px;
  z-index: 1100; // Increase z-index to ensure it's above other elements
  
  @media (max-width: 768px) {
    position: relative; // Change from static to relative for better control
  }
`;

// Fix the Button to ensure it's properly clickable
const LanguageButton = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  font-size: 0.9rem;
  color: #2c3e50;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 6px;
  transition: all 0.2s ease;
  position: relative; // Add position relative
  z-index: 1101; // Make sure the button is above backdrop
  
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
  
  .lang-icon {
    font-size: 1rem;
    margin-right: 6px;
  }
  
  .arrow {
    margin-left: 8px;
    font-size: 0.7rem;
    transition: transform 0.2s ease;
    transform: ${props => props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
  }
  
  .lang-name {
    @media (max-width: 768px) {
      display: none;
    }
  }
  
  body.rtl & {
    .lang-icon {
      margin-right: 0;
      margin-left: 6px;
    }
    
    .arrow {
      margin-left: 0;
      margin-right: 8px;
    }
  }
  
  @media (max-width: 768px) {
    padding: 8px;
    
    .lang-icon {
      margin-right: 30px;
    }
    
    .arrow {
      display: none;
    }
    
    body.rtl & .lang-icon {
      margin-left: 0;
    }
  }
`;

// Fix the dropdown positioning
const LanguageDropdown = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  background: white;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  overflow: hidden;
  min-width: 160px;
  opacity: ${props => props.isOpen ? 1 : 0};
  transform: ${props => props.isOpen ? 'translateY(0)' : 'translateY(-10px)'};
  visibility: ${props => props.isOpen ? 'visible' : 'hidden'};
  transition: opacity 0.25s ease, transform 0.25s ease, visibility 0.25s ease;
  z-index: 1200; // Ensure the dropdown is on top when visible
  
  body.rtl & {
    right: auto;
    left: 0;
  }
  
  @media (max-width: 768px) {
    position: fixed;
    top: auto;
    bottom: 20px;
    right: 20px;
    left: auto;
    min-width: 140px;
    
    body.rtl & {
      left: 20px;
      right: auto;
    }
  }
`;

const LanguageOption = styled.button`
  width: 100%;
  text-align: left;
  padding: 12px 16px;
  border: none;
  background: transparent;
  cursor: pointer;
  transition: background 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #2c3e50;
  font-size: 0.9rem;
  
  &:hover {
    background: #f0f7ff;
  }
  
  body.rtl & {
    text-align: right;
  }
  
  & + & {
    border-top: 1px solid #f0f4f8;
  }
`;

const CheckIcon = styled.span`
  color: #2563eb;
  display: ${props => props.visible ? 'block' : 'none'};
`;

// Fix the Backdrop to ensure it doesn't block the button
const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  z-index: 1090; // Ensure it's below the button but above other content
  display: ${props => props.isOpen ? 'block' : 'none'};
  pointer-events: ${props => props.isOpen ? 'auto' : 'none'}; // Only catch events when open
`;

// Update the component to add debugging and prevent default behavior
const LanguageSelector = () => {
  const { currentLanguage, changeLanguage, isRTL, userData } = useLanguage();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  
  const languages = [
    { code: 'en', name: 'English' },
    { code: 'ar', name: 'العربية' }
  ];
  
  const toggleDropdown = (e) => {
    // Stop event propagation to prevent conflicts
    e.stopPropagation();
    // Toggle dropdown state
    setIsOpen(!isOpen);
    console.log("Toggle dropdown clicked, new state:", !isOpen);
  };
  
  const closeDropdown = () => setIsOpen(false);
  
  const handleLanguageChange = (langCode) => {
    if (langCode !== currentLanguage) {
      console.log(`Language selection: ${langCode} (was: ${currentLanguage})`);
      changeLanguage(langCode);
    }
    closeDropdown();
  };
  
  // Update displayed language based on user preferences when they change
  useEffect(() => {
    if (userData?.prefs?.language && userData.prefs.language !== currentLanguage) {
      console.log(`Updating language from user prefs: ${userData.prefs.language}`);
      changeLanguage(userData.prefs.language);
    }
  }, [userData?.prefs?.language]);
  
  const getCurrentLanguageName = () => {
    const lang = languages.find(l => l.code === currentLanguage);
    return lang ? lang.name : 'English';
  };
  
  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      const container = document.getElementById('language-selector-container');
      if (container && !container.contains(event.target)) {
        closeDropdown();
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  // Close dropdown when pressing escape key
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        closeDropdown();
      }
    };
    
    document.addEventListener('keydown', handleEscape);
    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, []);
  
  return (
    <LanguageContainer id="language-selector-container" className={isRTL ? 'rtl' : ''}>
      <LanguageButton 
        onClick={(e) => toggleDropdown(e)} 
        isOpen={isOpen} 
        aria-label={t('header.switchLanguage')}
        aria-expanded={isOpen}
      >
        <FaGlobe className="lang-icon" />
        <span className="lang-name">{getCurrentLanguageName()}</span>
        <FaChevronDown className="arrow" />
      </LanguageButton>
      
      {/* Render Backdrop only when dropdown is open */}
      {isOpen && <Backdrop isOpen={true} onClick={closeDropdown} />}
      
      <LanguageDropdown isOpen={isOpen}>
        {languages.map((language) => (
          <LanguageOption 
            key={language.code}
            active={currentLanguage === language.code}
            onClick={() => handleLanguageChange(language.code)}
          >
            {language.name}
            <CheckIcon visible={currentLanguage === language.code}>
              <FaCheck />
            </CheckIcon>
          </LanguageOption>
        ))}
      </LanguageDropdown>
    </LanguageContainer>
  );
};

export default LanguageSelector;