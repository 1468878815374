import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes, css } from 'styled-components';
import { 
  FaTimes, 
  FaSearch, 
  FaGraduationCap, 
  FaUniversity, 
  FaHeart, 
  FaChevronLeft, 
  FaChevronRight, 
  FaRocket,
  FaFilter,
  FaStar,
  FaBookOpen,
  FaUserGraduate,
  FaLaptop,
  FaBuilding,
  FaCompass,
  FaMapMarkedAlt,
  FaSchool,
  FaLightbulb,
  FaRegLightbulb,
  FaClipboardCheck,
  FaChalkboardTeacher,
  FaGlobeAfrica,
  FaBook,
  FaBrain,
  FaPuzzlePiece,
  FaRegThumbsUp,
  FaCogs,
  FaUsers,
  FaCheckCircle,
  FaRegSmile,
  FaDollarSign,
  FaHandPointer,
  FaHandHoldingHeart,
  FaCrown,
  FaPalette,
  FaSlidersH,
  FaShieldAlt,
} from 'react-icons/fa';
import './BackgroundPattern.css';
import { useTranslation } from 'react-i18next';

// Animation keyframes
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const scaleIn = keyframes`
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const scaleOut = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0;
  }
`;

const float = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-8px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(37, 99, 235, 0.4);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(37, 99, 235, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(37, 99, 235, 0);
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const slideInLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const slideInRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const slideInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const gradientShift = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

// Add this utility function near your other animations
const responsiveFontSize = (baseSize, minSize, maxSize) => css`
  font-size: ${minSize}px;
  
  @media (min-width: 480px) {
    font-size: calc(${minSize}px + (${baseSize} - ${minSize}) * ((100vw - 480px) / (768 - 480)));
  }
  
  @media (min-width: 768px) {
    font-size: calc(${baseSize}px + (${maxSize} - ${baseSize}) * ((100vw - 768px) / (1920 - 768)));
  }

  
  @media (min-width: 1920px) {
    font-size: ${maxSize}px;
  }
`;

// Modal overlay that covers the entire screen
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  animation: ${props => props.isClosing ? fadeOut : fadeIn} 0.4s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  backdrop-filter: blur(5px);
  
  /* Ensure the loading screens don't appear on top of this */
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
`;

// Modal card with dynamic height based on content
const ModalCard = styled.div`
  background-color: white;
  border-radius: ${props => props.windowWidth <= 480 ? '15px' : '20px'};
  padding: ${props => {
    if (props.windowWidth <= 480) return '16px';
    if (props.windowWidth <= 768) return '24px';
    return '30px';
  }};
  width: ${props => {
    if (props.windowWidth <= 480) return '95%';
    if (props.windowWidth <= 768) return '92%';
    return '85%';
  }};
  max-width: ${props => props.windowWidth <= 1200 ? '680px' : '800px'};
  
  /* Dynamic height that adapts to content */
  height: auto;
  max-height: ${props => `${props.windowHeight * 0.85}px`};
  min-height: ${props => props.windowWidth <= 480 ? '450px' : '500px'};
  
  box-shadow: 0 10px 50px rgba(37, 99, 235, 0.25);
  position: relative;
  animation: ${props => props.isClosing ? scaleOut : scaleIn} 0.4s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
  border: 1px solid rgba(37, 99, 235, 0.15);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: ${props => props.windowWidth <= 480 ? '4px' : '5px'};
    background: linear-gradient(90deg, #2563eb, #60a5fa);
    background-size: 200% 100%;
    animation: ${gradientShift} 5s linear infinite;
    border-top-left-radius: ${props => props.windowWidth <= 480 ? '15px' : '20px'};
    border-top-right-radius: ${props => props.windowWidth <= 480 ? '15px' : '20px'};
  }
`;

// Content container that adapts to available space
const ModalContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  
  /* Allow content to determine height, but set constraints */
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  
  /* Better padding for scrollable content */
  padding: 10px 5px 10px 0;
  
  /* Improve scrollbar styling */
  &::-webkit-scrollbar {
    width: 4px;
  }
  
  &::-webkit-scrollbar-track {
    background: rgba(241, 245, 249, 0.5);
    border-radius: 10px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: rgba(37, 99, 235, 0.3);
    border-radius: 10px;
  }
  
  /* Smoother scrolling */
  scroll-behavior: smooth;
`;

// Slide container that centers content when possible
const SlideContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 750px;
`;

// Individual slides with better content handling
const SlideContent = styled.div`
  position: ${props => props.absolute ? 'absolute' : 'relative'};
  top: 0;
  left: 0;
  right: 0;
  opacity: ${props => props.active ? 1 : 0};
  visibility: ${props => props.active ? 'visible' : 'hidden'};
  transform: ${props => props.active ? 'translateX(0)' : props.direction === 'next' ? 'translateX(50px)' : 'translateX(-50px)'};
  transition: opacity 0.5s ease, transform 0.5s ease, visibility 0.5s;
  width: 100%;
  
  /* Center content when there's space */
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  ${props => props.active && css`
    animation: ${fadeIn} 0.5s ease forwards;
  `}
`;

const SlideTitle = styled.h2`
  display: flex;
  align-items: center;
  gap: 10px;
  ${responsiveFontSize(28, 18, 34)}
  font-weight: 700;
  background: linear-gradient(135deg, #2563eb 0%, #3b82f6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: clamp(10px, 2vw, 25px);
  text-align: center;
  justify-content: center;
  letter-spacing: -0.5px;
  line-height: 1.3;
  opacity: 0;
  animation: ${slideInUp} 0.6s ease forwards 0.2s;
  width: clamp(200px, 90%, 600px);
`;

const SlideDescription = styled.p`
  ${responsiveFontSize(17, 12, 18)}
  line-height: 1.7;
  color: #4b5563;
  margin-bottom: clamp(15px, 3vw, 30px);
  text-align: center;
  max-width: clamp(280px, 85%, 650px);
  margin-left: auto;
  margin-right: auto;
  opacity: 0;
  animation: ${slideInUp} 0.6s ease forwards 0.4s;
  font-weight: 400;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
  height: 90px;
  
  &::before {
    content: '';
    position: absolute;
    top: -10%;
    left: -10%;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    z-index: 0;

  }
  
  svg {
    font-size: 5rem;
    color: #2563eb;
    animation: ${float} 6s infinite ease-in-out;
    position: relative;
    z-index: 1;
    filter: drop-shadow(0 5px 15px rgba(37, 99, 235, 0.3));
  }
  
  @media (max-width: 768px) {
    height: 80px;
    margin-bottom: 15px;
  }
  
  @media (max-width: 480px) {
    height: 70px;
    margin-bottom: 10px;
    
    svg {
      font-size: 3.5rem;
    }
  }
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: ${props => props.smallScreen ? '1fr' : 'repeat(auto-fit, minmax(240px, 1fr))'};
  gap: clamp(8px, 1.5vw, 16px);
  margin-bottom: clamp(8px, 1vw, 15px);
  width: 100%;
  opacity: 0;
  animation: ${fadeIn} 0.8s ease forwards 0.6s;
`;

const FeatureItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 14px;
  padding: 14px;
  background: rgba(240, 249, 255, 0.7);
  border-radius: 14px;
  transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
  border: 1px solid rgba(186, 230, 253, 0.5);
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 3px;
    background: linear-gradient(90deg, #2563eb, #60a5fa);
    transition: width 0.3s ease;
  }
  
  &:hover {
    transform: translateY(-4px) scale(1.02);
    box-shadow: 0 8px 20px rgba(37, 99, 235, 0.15);
    background: rgba(240, 249, 255, 0.9);
    border-color: rgba(186, 230, 253, 0.8);
    
    &::before {
      width: 100%;
    }
    
    .icon {
      transform: scale(1.1) rotate(5deg);
      color: #1d4ed8;
    }
  }
  
  &:nth-child(odd) {
    animation-delay: 0.2s;
  }
  
  &:nth-child(even) {
    animation-delay: 0.4s;
  }
  
  .icon {
    color: #2563eb;
    font-size: 24px;
    flex-shrink: 0;
    transition: all 0.3s ease;
    background: rgba(219, 234, 254, 0.3);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(37, 99, 235, 0.1);
  }
  
  .content {
    flex: 1;
    
    h3 {
      margin: 0 0 4px 0;
      font-size: 16px;
      color: #1e293b;
      font-weight: 600;
      transition: color 0.3s ease;
    }
    
    p {
      margin: 0;
      font-size: 13px;
      color: #64748b;
      line-height: 1.4;
    }
  }
  
  &:hover .content h3 {
    color: #2563eb;
  }
  
  @media (max-width: 768px) {
    padding: 12px;
    gap: 10px;
    
    .icon {
      font-size: 20px;
      width: 36px;
      height: 36px;
    }
    
    .content h3 {
      font-size: 15px;
    }
    
    .content p {
      font-size: 12px;
      line-height: 1.3;
    }
  }
  
  @media (max-width: 480px) {
    padding: 10px;
    gap: 8px;
    
    .icon {
      font-size: 18px;
      width: 32px;
      height: 32px;
    }
    
    .content h3 {
      font-size: 14px;
    }
    
    .content p {
      font-size: 11px;
      line-height: 1.2;
    }
  }
`;

const HighlightText = styled.span`
  color: #2563eb;
  font-weight: 600;
`;

const NavigationControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  opacity: 0;
  animation: ${fadeIn} 0.8s ease forwards 0.8s;
`;

const NavButton = styled.button`
  display: flex;
  align-items: center;
  gap: clamp(5px, 1vw, 8px);
  padding: ${props => {
    const basePadding = props.primary ? 
      `clamp(8px, 1.2vw, 12px) clamp(14px, 2vw, 20px)` : 
      `clamp(7px, 1vw, 10px) clamp(12px, 1.5vw, 16px)`;
    return basePadding;
  }};
  background: ${props => props.primary 
    ? 'linear-gradient(135deg, #2563eb 0%, #3b82f6 100%)' 
    : 'rgba(241, 245, 249, 0.8)'};
  color: ${props => props.primary ? 'white' : '#64748b'};
  border: none;
  border-radius: 10px;
  font-weight: 600;
  ${props => props.primary 
    ? responsiveFontSize(15, 13, 16) 
    : responsiveFontSize(14, 10, 15)}
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
  box-shadow: ${props => props.primary 
    ? '0 4px 12px rgba(37, 99, 235, 0.2)' 
    : '0 2px 6px rgba(0, 0, 0, 0.05)'};
  
  &:hover {
    transform: translateY(-3px) scale(1.02);
    box-shadow: ${props => props.primary 
      ? '0 8px 20px rgba(37, 99, 235, 0.25)' 
      : '0 4px 10px rgba(0, 0, 0, 0.1)'};
    background: ${props => props.primary 
      ? 'linear-gradient(135deg, #1d4ed8 0%, #2563eb 100%)' 
      : '#e2e8f0'};
  }
  
  &:active {
    transform: translateY(0) scale(0.98);
  }
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
  
  /* Add a subtle pulse animation to the primary button */
  ${props => props.primary && css`
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 10px;
      box-shadow: 0 0 0 0 rgba(37, 99, 235, 0.4);
      animation: ${pulse} 2s infinite;
    }
  `}
  
  svg {
    transition: transform 0.3s ease;
  }
  
  &:hover svg {
    transform: ${props => props.primary && props.next ? 'translateX(3px)' : props.primary && !props.next ? 'translateX(-3px)' : 'none'};
  }
  
  @media (max-width: 768px) {
    padding: ${props => props.primary ? '10px 18px' : '8px 14px'};
    font-size: ${props => props.primary ? '14px' : '13px'};
  }
  
  @media (max-width: 480px) {
    padding: ${props => props.primary ? '8px 16px' : '7px 12px'};
    font-size: ${props => props.primary ? '13px' : '12px'};
  }
`;

const PaginationDots = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-bottom: 10px;
  opacity: 0;
  animation: ${fadeIn} 0.8s ease forwards 1s;
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${props => props.active ? '#2563eb' : '#e2e8f0'};
  transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
  cursor: pointer;
  
  ${props => props.active && css`
    transform: scale(1.3);
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.2);
  `}
  
  &:hover {
    transform: ${props => props.active ? 'scale(1.3)' : 'scale(1.1)'};
    background: ${props => props.active ? '#2563eb' : '#cbd5e1'};
  }
  
  @media (max-width: 480px) {
    width: 8px;
    height: 8px;
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 4px;
  background: #e2e8f0;
  border-radius: 2px;
  margin-bottom: 12px;
  position: relative;
  overflow: hidden;
  opacity: 0;
  animation: ${fadeIn} 0.8s ease forwards 1s;
`;

const ProgressIndicator = styled.div`
  height: 100%;
  background: linear-gradient(90deg, #2563eb, #60a5fa);
  border-radius: 2px;
  width: ${props => (props.currentSlide / (props.totalSlides - 1)) * 100}%;
  transition: width 0.4s ease;
`;

// Additional fancy components
const KeyPointBox = styled.div`
  background: linear-gradient(135deg, rgba(37, 99, 235, 0.05) 0%, rgba(96, 165, 250, 0.05) 100%);
  border: 1px solid rgba(37, 99, 235, 0.1);
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;
  opacity: 0;
  animation: ${fadeIn} 0.8s ease forwards 0.7s;
  width: 100%;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background: linear-gradient(180deg, #2563eb, #60a5fa);
  }
  
  h3 {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 17px;
    font-weight: 600;
    color: #1e293b;
    margin: 0 0 10px 0;
  }
  
  p {
    color: #4b5563;
    margin: 0;
    line-height: 1.5;
    font-size: 14px;
  }
  
  ul {
    margin: 8px 0 0 0;
    padding-left: 20px;
    
    li {
      margin-bottom: 5px;
      color: #4b5563;
      position: relative;
      font-size: 13px;
      
      &::marker {
        color: #2563eb;
      }
    }
  }
  
  @media (max-width: 768px) {
    padding: 14px;
    
    h3 {
      font-size: 16px;
      margin-bottom: 8px;
    }
    
    p {
      font-size: 13px;
      line-height: 1.4;
    }
    
    ul {
      margin-top: 6px;
      
      li {
        font-size: 12px;
        margin-bottom: 4px;
      }
    }
  }
  
  @media (max-width: 480px) {
    padding: 12px;
    
    h3 {
      font-size: 15px;
      margin-bottom: 6px;
    }
    
    p {
      font-size: 12px;
      line-height: 1.3;
    }
    
    ul {
      margin-top: 4px;
      padding-left: 16px;
      
      li {
        font-size: 11px;
        margin-bottom: 3px;
      }
    }
  }
`;

const InlineFeatures = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: center;
  margin-bottom: 15px;
  opacity: 0;
  animation: ${fadeIn} 0.8s ease forwards 0.6s;
  
  @media (max-width: 768px) {
    gap: 10px;
    margin-bottom: 12px;
  }
  
  @media (max-width: 480px) {
    gap: 8px;
    margin-bottom: 10px;
  }
`;

const InlineFeature = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  background: rgba(240, 249, 255, 0.7);
  border-radius: 30px;
  padding: 7px 14px;
  border: 1px solid rgba(186, 230, 253, 0.5);
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(37, 99, 235, 0.1);
    background: rgba(240, 249, 255, 0.9);
    border-color: rgba(186, 230, 253, 0.8);
    
    .icon {
      transform: rotate(15deg);
      color: #1d4ed8;
    }
  }
  
  .icon {
    color: #2563eb;
    font-size: 16px;
    transition: all 0.3s ease;
  }
  
  span {
    font-size: 13px;
    color: #334155;
    font-weight: 500;
  }
  
  @media (max-width: 768px) {
    padding: 6px 12px;
    
    .icon {
      font-size: 14px;
    }
    
    span {
      font-size: 12px;
    }
  }
  
  @media (max-width: 480px) {
    padding: 5px 10px;
    
    .icon {
      font-size: 12px;
    }
    
    span {
      font-size: 11px;
    }
  }
`;
const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: rgba(241, 245, 249, 0.8);
  border: none;
  font-size: 16px;
  color: #64748b;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  padding: 0;
  border-radius: 50%;
  transition: all 0.3s ease;
  z-index: 10;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  
  &:hover {
    background: #f1f5f9;
    color: #ef4444;
    transform: rotate(90deg);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
`;

const WelcomeModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isClosing, setIsClosing] = useState(false);
  const [slideDirection, setSlideDirection] = useState('next');
  const slidesRef = useRef([]);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  
  // Window resize handler
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Calculate dynamic sizes based on window
  const getDynamicSize = (base, minSize, maxSize) => {
    const size = Math.max(minSize, Math.min(base, maxSize));
    return size;
  };
  
  // Get the appropriate height based on screen size
  const getSlideHeight = (slideIndex) => {
    if (windowSize.width <= 390) {
      return mobileHeights[slideIndex];
    } else if (windowSize.width <= 700) {
      return mobilesemiHeights[slideIndex];
    // Add specific iPad sizing breakpoints
    } else if (windowSize.width >= 768 && windowSize.width <= 1024) {
      return ipadHeights[slideIndex];
    } else if (windowSize.width <= 1200) {
      return mobileMediumHeights[slideIndex];
    } else if (windowSize.width <= 1201) {
      return slideHeights[slideIndex];
    } else {
      // For larger screens, scale up the height proportionally
      const baseHeight = slideHeights[slideIndex];
      return getDynamicSize(baseHeight * 1.1, baseHeight, baseHeight * 1.3);
    }
  };
  
  // Define custom height for each slide
  const slideHeights = {
    0: 750, // Welcome slide
    1: 720, // Find your perfect university
    2: 840, // Personalized Experience
    3: 840, // Discover Top Programs & Universities
    4: 840  // Ready to Begin Your Journey
  };
  
  // Mobile heights (for smaller screens)
  const mobileHeights = {
    0: 610,
    1: 610,
    2: 610,
    3: 650,
    4: 650
  };
  // Mobile heights (for smaller screens)
  const mobilesemiHeights = {
    0: 590,
    1: 710,
    2: 780,
    3: 810,
    4: 810
  };
  // Medium mobile heights (for tablets)
  const mobileMediumHeights = {
    0: 680,
    1: 790,
    2: 810,
    3: 870,
    4: 870
  };
  
  // Add new heights specifically for iPad screens
  const ipadHeights = {
    0: 750, // Welcome slide
    1: 750, // Find your perfect university
    2: 780, // Personalized Experience
    3: 780, // Discover Top Programs & Universities
    4: 780  // Ready to Begin Your Journey
  };
  
  const slides = [
    {
      title: t('welcomeModal.slides.welcome.title'),
      icon: <FaRocket />,
      description: t('welcomeModal.slides.welcome.description'),
      content: (
        <>
          <KeyPointBox>
            <h3>
              <FaLightbulb style={{ color: '#2563eb' }} /> {t('welcomeModal.slides.welcome.keyPointTitle')}
            </h3>
            <p>{t('welcomeModal.slides.welcome.keyPointText')}</p>
            <ul>
              <li>
                {t('welcomeModal.slides.welcome.bullet1.part1')}
                <HighlightText>{t('welcomeModal.slides.welcome.bullet1.highlight')}</HighlightText>
                {t('welcomeModal.slides.welcome.bullet1.part2')}
              </li>
              <li>
                {t('welcomeModal.slides.welcome.bullet2')}
              </li>
              <li>
                {t('welcomeModal.slides.welcome.bullet3')}
              </li>
            </ul>
          </KeyPointBox>
        </>
      )
    },
    {
      title: t('welcomeModal.slides.universities.title'),
      icon: <FaSearch />,
      description: t('welcomeModal.slides.universities.description'),
      features: [
        {
          icon: <FaUniversity className="icon" />,
          title: t('welcomeModal.slides.universities.features.database.title'),
          description: t('welcomeModal.slides.universities.features.database.description')
        },
        {
          icon: <FaGraduationCap className="icon" />,
          title: t('welcomeModal.slides.universities.features.exploration.title'),
          description: t('welcomeModal.slides.universities.features.exploration.description')
        },
        {
          icon: <FaFilter className="icon" />,
          title: t('welcomeModal.slides.universities.features.filtering.title'),
          description: t('welcomeModal.slides.universities.features.filtering.description')
        },
        {
          icon: <FaCompass className="icon" />,
          title: t('welcomeModal.slides.universities.features.comparisons.title'),
          description: t('welcomeModal.slides.universities.features.comparisons.description')
        }
      ]
    },
    {
      title: t('welcomeModal.slides.personalized.title'),
      icon: <FaUserGraduate />,
      description: t('welcomeModal.slides.personalized.description'),
      features: [
        {
          icon: <FaStar className="icon" />,
          title: t('welcomeModal.slides.personalized.features.recommendations.title'),
          description: t('welcomeModal.slides.personalized.features.recommendations.description')
        },
        {
          icon: <FaHeart className="icon" />,
          title: t('welcomeModal.slides.personalized.features.favorites.title'),
          description: t('welcomeModal.slides.personalized.features.favorites.description')
        },
        {
          icon: <FaBookOpen className="icon" />,
          title: t('welcomeModal.slides.personalized.features.majorProfiles.title'),
          description: t('welcomeModal.slides.personalized.features.majorProfiles.description')
        },
        {
          icon: <FaHandHoldingHeart className="icon" />,
          title: t('welcomeModal.slides.personalized.features.tailored.title'),
          description: t('welcomeModal.slides.personalized.features.tailored.description')
        }
      ],
      content: (
        <>
          <InlineFeatures>
            <InlineFeature>
              <FaBrain className="icon" />
              <span>{t('welcomeModal.slides.personalized.inline.interestMatching')}</span>
            </InlineFeature>
            <InlineFeature>
              <FaPuzzlePiece className="icon" />
              <span>{t('welcomeModal.slides.personalized.inline.perfectFit')}</span>
            </InlineFeature>
            <InlineFeature>
              <FaHandPointer className="icon" />
              <span>{t('welcomeModal.slides.personalized.inline.interactive')}</span>
            </InlineFeature>
            <InlineFeature>
              <FaRegSmile className="icon" />
              <span>{t('welcomeModal.slides.personalized.inline.userFriendly')}</span>
            </InlineFeature>
          </InlineFeatures>
        </>
      )
    },
    {
      title: t('welcomeModal.slides.programs.title'),
      icon: <FaMapMarkedAlt />,
      description: t('welcomeModal.slides.programs.description'),
      features: [
        {
          icon: <FaCrown className="icon" />,
          title: t('welcomeModal.slides.programs.features.trendingMajors.title'),
          description: t('welcomeModal.slides.programs.features.trendingMajors.description')
        },
        {
          icon: <FaSchool className="icon" />,
          title: t('welcomeModal.slides.programs.features.rankings.title'),
          description: t('welcomeModal.slides.programs.features.rankings.description')
        },
        {
          icon: <FaPalette className="icon" />,
          title: t('welcomeModal.slides.programs.features.interface.title'),
          description: t('welcomeModal.slides.programs.features.interface.description')
        },
        {
          icon: <FaGlobeAfrica className="icon" />,
          title: t('welcomeModal.slides.programs.features.international.title'),
          description: t('welcomeModal.slides.programs.features.international.description')
        }
      ],
      content: (
        <>
          <KeyPointBox>
            <h3><FaRegLightbulb style={{ color: '#2563eb' }} /> {t('welcomeModal.slides.programs.keyPointTitle')}</h3>
            <p>{t('welcomeModal.slides.programs.keyPointText')}</p>
            <ul>
              <li>{t('welcomeModal.slides.programs.bullet1')}</li>
              <li>{t('welcomeModal.slides.programs.bullet2')}</li>
            </ul>
          </KeyPointBox>
        </>
      )
    },
    {
      title: t('welcomeModal.slides.ready.title'),
      icon: <FaRegThumbsUp />,
      description: t('welcomeModal.slides.ready.description'),
      features: [
        {
          icon: <FaSearch className="icon" />,
          title: t('welcomeModal.slides.ready.features.startSearching.title'),
          description: t('welcomeModal.slides.ready.features.startSearching.description')
        },
        {
          icon: <FaSlidersH className="icon" />,
          title: t('welcomeModal.slides.ready.features.applyFilters.title'),
          description: t('welcomeModal.slides.ready.features.applyFilters.description')
        },
        {
          icon: <FaStar className="icon" />,
          title: t('welcomeModal.slides.ready.features.saveFavorites.title'),
          description: t('welcomeModal.slides.ready.features.saveFavorites.description')
        },
        {
          icon: <FaUsers className="icon" />,
          title: t('welcomeModal.slides.ready.features.shareInsights.title'),
          description: t('welcomeModal.slides.ready.features.shareInsights.description')
        }
      ],
      content: (
        <>
          <KeyPointBox>
            <h3><FaShieldAlt style={{ color: '#2563eb' }} /> {t('welcomeModal.slides.ready.keyPointTitle')}</h3>
            <p>{t('welcomeModal.slides.ready.keyPointText')}</p>
            <ul>
              <li>{t('welcomeModal.slides.ready.bullet1')}</li>
              <li>{t('welcomeModal.slides.ready.bullet2')}</li>
              <li>{t('welcomeModal.slides.ready.bullet3')}</li>
              <li>{t('welcomeModal.slides.ready.bullet4')}</li>
            </ul>
          </KeyPointBox>
        </>
      )
    }
  ];

  const handleNextSlide = () => {
    if (currentSlide < slides.length - 1) {
      setSlideDirection('next');
      setCurrentSlide(currentSlide + 1);
    }
  };

  const handlePrevSlide = () => {
    if (currentSlide > 0) {
      setSlideDirection('prev');
      setCurrentSlide(currentSlide - 1);
    }
  };

  const handleDotClick = (index) => {
    setSlideDirection(index > currentSlide ? 'next' : 'prev');
    setCurrentSlide(index);
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      setIsClosing(false);
      setCurrentSlide(0);
      localStorage.setItem('hasSeenWelcomeModal', 'true');
    }, 400);
  };

  // Render features provided in a slide
  const renderFeatures = (features) => {
    const displayFeatures = windowSize.width < 380 ? features.slice(0, 8) : features;
    return (
      <FeatureGrid smallScreen={windowSize.width <= 480}>
        {displayFeatures.map((feature, featureIndex) => (
          <FeatureItem key={featureIndex} smallScreen={windowSize.width <= 480}>
            {feature.icon}
            <div className="content">
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          </FeatureItem>
        ))}
      </FeatureGrid>
    );
  };

  if (!isOpen) return null;

  return (
    <ModalOverlay isClosing={isClosing} onClick={handleClose}>
      <ModalCard 
        isClosing={isClosing} 
        onClick={e => e.stopPropagation()}
        slideHeight={getSlideHeight(currentSlide)}
        windowWidth={windowSize.width}
        windowHeight={windowSize.height}
      >
        <CloseButton onClick={handleClose}>
          <FaTimes />
        </CloseButton>
        
        <ModalContent 
          windowWidth={windowSize.width}
          windowHeight={windowSize.height}
          slideHeight={getSlideHeight(currentSlide)}
        >
          <SlideContainer windowWidth={windowSize.width}>
            {slides.map((slide, index) => (
              <SlideContent 
                key={index} 
                active={currentSlide === index}
                direction={slideDirection}
                absolute={true}
                windowWidth={windowSize.width}
                ref={el => slidesRef.current[index] = el}
              >
                <IconContainer>
                  {slide.icon}
                </IconContainer>
                
                <SlideTitle>
                  {slide.title}
                </SlideTitle>
                
                <SlideDescription>
                  {slide.description}
                </SlideDescription>
                
                {slide.features && renderFeatures(slide.features)}
                
                {slide.content}
              </SlideContent>
            ))}
          </SlideContainer>
        </ModalContent>
        
        <ProgressBar>
          <ProgressIndicator 
            currentSlide={currentSlide} 
            totalSlides={slides.length}
          />
        </ProgressBar>
        
        <PaginationDots>
          {slides.map((_, index) => (
            <Dot 
              key={index} 
              active={currentSlide === index}
              onClick={() => handleDotClick(index)}
            />
          ))}
        </PaginationDots>
        
        <NavigationControls>
          <NavButton 
            onClick={handlePrevSlide}
            disabled={currentSlide === 0}
          >
            <FaChevronLeft /> {t('welcomeModal.buttons.previous')}
          </NavButton>
          
          {currentSlide < slides.length - 1 ? (
            <NavButton 
              primary
              next
              onClick={handleNextSlide}
            >
              {t('welcomeModal.buttons.next')} <FaChevronRight />
            </NavButton>
          ) : (
            <NavButton 
              primary
              onClick={handleClose}
            >
              {t('welcomeModal.buttons.getStarted')} <FaRocket />
            </NavButton>
          )}
        </NavigationControls>
      </ModalCard>
    </ModalOverlay>
  );
};

export default WelcomeModal;