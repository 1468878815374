import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaLinkedin, FaEnvelope, FaWhatsapp, FaChevronUp } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import logo from '../assets/logo.webp';
import './Footer.css';
import { Client, Account } from 'appwrite';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../contexts/LanguageContext';

const client = new Client();
client.setEndpoint('https://cloud.appwrite.io/v1')
    .setProject('6748fa610024d013c88b');

const account = new Account(client);

const Footer = () => {
    const { t } = useTranslation();
    const { isRTL } = useLanguage();
    const [user, setUser] = useState(null);
    const [showScrollTop, setShowScrollTop] = useState(false);

    useEffect(() => {
        account.get()
            .then(setUser)
            .catch(() => setUser(null));
            
        // Add scroll event listener for scroll-to-top button
        const handleScroll = () => {
            if (window.pageYOffset > 300) {
                setShowScrollTop(true);
            } else {
                setShowScrollTop(false);
            }
        };
        
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const footerLinks = [
        { to: "/", label: t('footer.home') },
        { to: "/about", label: t('footer.about') },
        { to: "/contactus", label: t('footer.contact') },
        { to: "/PrivacyPolicy", label: t('footer.privacy') },
        { to: "/TermsOfService", label: t('footer.terms') }
    ];
      
    return (
        <footer className={`footer ${isRTL ? 'rtl' : ''}`}>
            <div className="footer-container">
                <div className="footer-content">
                    <div className="footer-logo-section">
                        <Link to="https://edufinders.com/" className="footer-logo-link">
                            <img src={logo} alt="EDU Finders Logo" className="footer-logo" />
                        </Link>
                    </div>

                    {/* Legal links are now always visible */}
                    <div className="footer-sections">
                        <div className="footer-nav">
                        {footerLinks.map(link => (
                              <Link key={link.to} to={link.to} className="footer-link">
                               {link.label}
                                </Link>
                        ))}
                        </div>

                        {user && (
                            <div className="social-links">
                                <a href="https://facebook.com/edufinderss" target="_blank" rel="noopener noreferrer" className="social-link" aria-label="Facebook">
                                    <FaFacebook />
                                </a>
                                <a href="https://x.com/EDU_Finders" target="_blank" rel="noopener noreferrer" className="social-link" aria-label="Twitter">
                                    <FaXTwitter />
                                </a>
                                <a href="https://www.instagram.com/edu_finders/" target="_blank" rel="noopener noreferrer" className="social-link" aria-label="Instagram">
                                    <FaInstagram />
                                </a>
                                <a href="https://www.linkedin.com/company/edufinders/" target="_blank" rel="noopener noreferrer" className="social-link" aria-label="LinkedIn">
                                    <FaLinkedin />
                                </a>
                                <a href="https://wa.me/201070219376" target="_blank" rel="noopener noreferrer" className="social-link" aria-label="WhatsApp">
                                    <FaWhatsapp />
                                </a>
                                <a href="mailto:info@edufinders.com" className="social-link" aria-label="Email">
                                    <FaEnvelope />
                                </a>
                            </div>
                        )}
                    </div>
                </div>
                <div className="footer-bottom">
                    <p className="copyright">{t('footer.copyright')}</p>
                </div>
            </div>
            
            {/* Scroll to top button */}
            {showScrollTop && (
                <button className="scroll-top-button" onClick={scrollToTop} aria-label={t('footer.aria.scrollToTop')}>
                    <FaChevronUp />
                </button>
            )}
        </footer>
    );
};

export default Footer;