import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  const prevPathRef = useRef(pathname);

  useEffect(() => {
    // Only scroll when pathname actually changes
    if (prevPathRef.current !== pathname) {
      // Immediate scroll to top
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto' // Use 'auto' instead of 'smooth' for more reliable behavior
      });
      
      // Add a secondary scroll with a delay to handle any transitions
      const timeoutId = setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'auto'
        });
      }, 100);
      
      // Add a third scroll with a longer delay to handle complex page transitions
      const finalTimeoutId = setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'auto'
        });
        // Update document element and body scrollTop directly as a fallback
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
      }, 500);
      
      // Update the previous pathname
      prevPathRef.current = pathname;
      
      return () => {
        clearTimeout(timeoutId);
        clearTimeout(finalTimeoutId);
      };
    }
  }, [pathname]);

  return null;
};

export default ScrollToTop;